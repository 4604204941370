import React, { useCallback, useState } from 'react';
import { Collapse, Button, makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

export const AgendaDropdown = ({ label, children }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const toggleOpen = useCallback(() => {
    setOpen((open) => !open);
  }, []);

  return (
    <div>
      <Button className={classes.button} fullWidth onClick={toggleOpen}>
        {label}
        {children && children.length > 0 && <>{open ? <ExpandMoreIcon /> : <ChevronRightIcon />}</>}
      </Button>
      <Collapse in={open} timeout='auto' unmountOnExit>
        {children}
      </Collapse>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: 'rgba(164, 198, 39, 0.3)',
    border: 'solid 2px  rgba(164, 198, 39, 1)',
    display: 'inline-flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    textTransform: 'none',
    fontWeight: 'bolder',
  },
}));
