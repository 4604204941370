import { Button, Collapse, makeStyles, Divider, Grid, Box, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useCallback, useMemo, useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

export const AgendaTopicsSchedule = ({ time, title, groups }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const toggleOpen = useCallback(() => {
    setOpen((open) => !open);
  }, []);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isiPad = useMediaQuery(theme.breakpoints.down('md'));

  const speakerList = useMemo(() => {
    if (!groups?.length) return null;

    return groups.map((group, index, array) => {
      const isLast = index === array.length - 1;
      return (
        <div>
          <h3 className={classes.group}>{group.name}:</h3>
          {group.speakers.map((speaker) => {
            return (
              <div className={classes.speaker}>
                <img
                  className={classes.image}
                  src={
                    speaker.file && speaker.file.code && `${process.env.GATSBY_CMS_API_URL}/static/${speaker.file.code}`
                  }></img>
                <h3 className={classes.name}>{`${speaker.salutation.name !== 'NULL' ? speaker.salutation.name : ''} ${
                  speaker.firstName
                } ${speaker.lastName}, ${speaker.designation}, ${speaker.organization}`}</h3>
              </div>
            );
          })}
          {!isLast && <Divider />}
        </div>
      );
    });
  }, [groups, classes]);

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12} sm={3} md={3} lg={2} xl={2}>
        <div
          style={{
            fontSize: isMobile ? '16px' : isSmall ? '18px' : isiPad ? '20px' : '22px',
            height: !open ? '60px' : isMobile ? '60px' : '100%',
            transition: 'height 0.2s',
          }}
          className={classes.time}>
          {time}
        </div>
      </Grid>
      <Grid item xs={12} sm={9} md={9} lg={10} xl={10} className={classes.topicContainer}>
        <Button disableFocusRipple className={classes.button} fullWidth onClick={toggleOpen}>
          <span className={classes.title}>{title} </span>
          {speakerList && speakerList.length > 0 && <>{open ? <ExpandMoreIcon /> : <ChevronRightIcon />}</>}
        </Button>
        <Collapse in={open} timeout='auto' unmountOnExit>
          {speakerList}
        </Collapse>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'flex',
    marginTop: theme.spacing(5),
    // marginBottom: theme.spacing(3),
  },
  button: {
    color: 'white',
    display: 'inline-flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
    textTransform: 'none',
  },
  topicContainer: {
    // marginLeft: theme.spacing(3),
  },
  title: {
    color: 'rgba(164, 198, 39, 1)',
    textAlign: 'left',
  },

  group: {
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(0)}px`,
  },

  speaker: {
    display: 'flex',
  },

  name: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(2),
  },
  image: {
    height: '90px',
    width: '70px',
    objectFit: 'cover',
    padding: `${theme.spacing(0)}px ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(0)}px`,
  },
  time: {
    border: 'solid 2px rgba(164, 198, 39, 1)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    padding: `${theme.spacing(0)}px ${theme.spacing(2)}px ${theme.spacing(0)}px ${theme.spacing(2)}px`,
    borderRadius: theme.shape.borderRadius,
  },
}));
