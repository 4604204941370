import { Box, Button, Grid, makeStyles, useMediaQuery, useTheme, Typography, IconButton } from '@material-ui/core';
import axios from 'axios';
import HomepageData from '../../data/homepage.json';
import SectionContentFeaturedList from '../../components/section-content-featured-list';
import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import AnimatedCard from '../../components/card-image-title-description/animated-card';
import CustomGridItem from '../../components/custom-grid/custom-grid-item';
import Layout from '../../components/layout';
import SectionContainerLayoutWithFilter from '../../components/section-container-layout-with-filter';
import SEO from '../../components/seo';
import CustomGrid from '../../components/custom-grid/custom-grid';
import ScrollbarAlwaysOn from '../../components/scrollbar/scrollbar-always-on';
import SectionDescriptionInnerHTML from '../../components/section-description-innerhtml';
import banner from '../../images/summits/2022-summit/v3-See-you-banner.jpg';
import colors from '../../constants/colors';
import { useCustomGridBreakpoints } from '../../components/custom-grid/useCustomGridBreakpoints';
import { Tabs } from '../../components/tabs/Tabs';
import { AgendaDropdown } from '../../components/agenda-dropdown/agenda-dropdown';
import Fade from 'react-reveal/Fade';
import moment from 'moment';
import { Link } from 'gatsby';
import ReactPlayer from 'react-player';
import VolumeControlFloat from '../../components/volume-control';
import { VolumeOff, VolumeUp } from '@material-ui/icons';

import { AgendaTopicsSchedule } from '../../components/agenda-topics-schedule/agenda-topics-schedule';

const Page_2022 = ({ pageContext: { _partner2022 } }) => {
  const [speakers2022, setSpeakers2022] = useState([]);
  const [agenda2022, setAgenda2022] = useState([]);
  const [news, setNews] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isSpeakersShownAll, setIsSpeakersShownAll] = useState(false);
  const [isShowPartners, setIsShowPartners] = useState(true);
  const { isDownXs, isDownSm, isDownMd, isDownLg } = useCustomGridBreakpoints();
  const [currentTab, setCurrentTab] = useState(0);
  const fullVideoRef = useRef(null);
  const [videoBgStartPlay, setVideoBgStartPlay] = useState(false);
  const [showFloatingIcon, setShowFloatingIcon] = useState(false);
  const [isFadeIn, setIsFadeIn] = useState(false);

  const classes = useStyles();
  let _partnersCount = 0;

  const orderData = (items) => {
    return [...items].sort((a, b) => {
      return a.order - b.order;
    });
  };

  const toggleBgVideoVolume = () => {
    setIsMuted(!isMuted);
  };

  const resetPageData = useCallback(async () => {
    const payload = { filters: { search: '', code: '' } };
    const [speakerResponse, newsResponse, agendaResponse] = await Promise.all([
      axiosInstance.get(`/speaker`),
      axiosInstance.get(`/news`),
      axiosInstance.post(`/agenda/search`, payload),
    ]);

    setNews(getPageYearData(newsResponse.data));
    setAgenda2022(agendaResponse.data);
    setSpeakers2022(orderData(getPageYearData(speakerResponse.data)));
  }, []);

  const agendas = useMemo(() => {
    return Object.entries(HomepageData.agenda.November);
  }, []);

  const videoIsReady = () => {
    fullVideoRef.current.seekTo(2, 'seconds');
    const fadeinTimeOut1 = setTimeout(() => {
      setIsFadeIn(true);
      clearTimeout(fadeinTimeOut1);
    }, 1000);
    // setIsFadeIn(true);
    const fadeinTimeOut = setTimeout(() => {
      setIsFadeIn(false);
      clearTimeout(fadeinTimeOut);
    }, 5000);
  };

  const agendaLocations = useMemo(() => {
    const agenda = agenda2022[currentTab];
    if (agenda?.locations) {
      return agenda.locations;
    }
    return [];
  }, [agenda2022, currentTab]);

  const agendaDate = useMemo(() => {
    const agenda = agenda2022[currentTab];

    return agenda && <Box className={classes.agendaDate}>{moment(agenda.dateTime).format('dddd, MMMM Do YYYY')}</Box>;
  }, [currentTab, agenda2022]);

  const tabs = useMemo(() => {
    return agenda2022.map((agenda) => {
      return { label: agenda.name };
    });
  }, [agenda2022]);

  const toggleViewAllSpeakers = useCallback(() => {
    setIsSpeakersShownAll((value) => !value);
  }, []);

  const speakersSectionData = useMemo(() => {
    if (isSpeakersShownAll) {
      return speakers2022;
    }
    if (isDownXs) {
      return speakers2022.slice(0, 3);
    }
    if (isDownSm) {
      return speakers2022.slice(0, 6);
    }
    if (isDownMd) {
      return speakers2022.slice(0, 9);
    }
    if (isDownLg) {
      return speakers2022.slice(0, 12);
    }
    return speakers2022.slice(0, 12);
  }, [isSpeakersShownAll, speakers2022, isDownXs, isDownSm, isDownMd, isDownLg]);

  const newsData = useMemo(() => {
    return news.map((news) => ({ ...news, file: [news.file] }));
  }, [news]);

  useEffect(() => {
    resetPageData();
  }, [resetPageData]);

  const [isMuted, setIsMuted] = useState(true);

  const handleBgVideoVolume = () => {
    setIsMuted(true);
  };

  const paragraph = useMemo(() => {
    return `GMIS America will discuss, debate and shape the future of industrialisation against the backdrop of climate change. The summit will discuss how 4IR technologies can be harnessed to achieve global industrialisation, net zero and prosperity for all.
 <ul class="${classes.contentParagraph} ${classes.contentList}">
 <li>Autonomous systems and robotics</li>
 <li>Decentralisation of supply chains</li>
 <li>The hydrogen economy</li>
 <li>Additive manufacturing</li>
 <li>Industrial safety</li>
 <li>The industrial energy transition</li>
 <li>Nanotech and advanced material design</li>
 <li> Open source Manufacturing</li>
 </ul>
 `;
  }, [classes]);

  return (
    <Layout>
      <SEO lang='en' title='#GMIS2022' />
      <Box display='block' width='100vw' position='relative' className={classes.boxStyle}>
        <Grid container alignItems='center' justify='center'>
          <img width='100%' src={banner} />
          <Box position='absolute' bottom={isMobile ? '15px' : '40px'} left='6%'>
            <Link
              to='/summits/2022'
              style={{
                textDecoration: 'none',
              }}>
              <Button
                variant='contained'
                style={{
                  backgroundColor: '#070e40',
                  color: '#a4c627',
                  padding: '0px 0px 0px 10px',
                  borderColor: '#070e40',
                }}>
                <Typography
                  style={{
                    fontWeight: 'bold',
                  }}
                  variant={isMobile ? 'caption' : 'h6'}>
                  LEARN MORE
                </Typography>
                <ArrowRightIcon
                  style={{
                    fontSize: isMobile ? '1.2rem' : '2.4rem',
                    color: 'white',
                  }}
                />
              </Button>
            </Link>
          </Box>

          {/* <Box
                className={classes.videoTextOverlay}
                clone
                position='absolute'
                zIndex='appBar'
                width={isMobile ? '70%' : 'auto'}
                p={isMobile ? 2 : 6}>
                <Fade in={isFadeIn} timeout={1000}>
                  <Grid item xs={12}>
                    <Typography variant='h4' color='textPrimary' align='center'>
                      <Box fontWeight='fontWeightBold' fontSize={isMobile ? 16 : null}>
                        22-27 NOVEMBER 2021, EXPO DUBAI
                      </Box>
                    </Typography>
                  </Grid>
                </Fade>
                
              </Box> */}
          {/* <Link to='#agenda'>
                <Box width='100vw'>
                  {isMobile ? (
                    <img width='100%' src='./images/gmis-static-banner.png' />
                  ) : (
                    <img width='100%' src='./images/gmis-static-banner.png' />
                  )}
                </Box>
              </Link> */}
          {/* <ReactPlayer
            // url='https://videos.gmisummit.com/hls/gmis-2021-homepage-video/1080_out.m3u8'
            // url='https://videos.gmisummit.com/hls/GMIS-MainHomepage-America-Announcement-Short/stream.m3u8'
            url='https://videos.gmisummit.com/hls/gmis-2022-america-wrap-up/stream.m3u8'
            playing
            ref={fullVideoRef}
            muted={isMuted}
            onPlay={videoIsReady}
            playsinline
            width='100%'
            loop
            height='100%'
            onStart={() => setVideoBgStartPlay(true)}
          /> */}
        </Grid>
        {showFloatingIcon && (
          <VolumeControlFloat callbackFn={toggleBgVideoVolume}>
            <IconButton color='primary' aria-label='Volume-Control' size='small'>
              {isMuted ? <VolumeOff /> : <VolumeUp />}
            </IconButton>
          </VolumeControlFloat>
        )}
      </Box>
      <Grid container>
        <Grid xs={12} item id='watch-live-stream'>
          <SectionDescriptionInnerHTML
            download={false}
            brochure={false}
            callbackFn={handleBgVideoVolume}
            muted={isMuted}
            videoControls={true}
            left={true}
            image={false}
            videoThumbNail='GMIS-2022-America-Announcement-thumbnail-new'
            url='https://videos.gmisummit.com/hls/GMIS-2022-America-Announcement-Short/stream.m3u8'
            title='Advancing global industrialisation and net zero'
            paragraphText={paragraph}
            ctaText='Download Agenda'
            ctaLink='/pdfs/gmis-america-agenda.pdf'
            ctaLinkExternal
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          {agenda2022 && agenda2022.length > 0 && (
            <SectionContainerLayoutWithFilter title='GMIS AMERICA 2022 AGENDA'>
              <Box mt={5}>
                <Tabs value={currentTab} onChange={setCurrentTab} tabs={tabs}>
                  {agendaDate}
                  {agendaLocations.map((location) => {
                    // console.log('location', location);
                    return (
                      <Box mt={2} key={location.code}>
                        <AgendaDropdown label={<span>{location.name}</span>}>
                          {location.topics
                            .sort((a, b) => new Date(a.startDateTime) - new Date(b.startDateTime))
                            .map((topic) => {
                              return (
                                <Box key={topic.code}>
                                  <AgendaTopicsSchedule
                                    time={`${moment(topic.startDateTime).format('HH:mm')} - ${moment(
                                      topic.endDateTime
                                    ).format('HH:mm')}`}
                                    title={topic.name}
                                    groups={topic.groups}
                                  />
                                </Box>
                              );
                            })}
                        </AgendaDropdown>
                      </Box>
                    );
                  })}
                </Tabs>
              </Box>
            </SectionContainerLayoutWithFilter>
          )}
        </Grid>

        <Grid item xs={12}>
          {_partner2022 && _partner2022.length > 0 && isShowPartners && (
            <SectionContainerLayoutWithFilter
              title='GMIS AMERICA 2022 PARTNERS'
              isViewAll
              viewAllText='view all'
              baseLink='/partners'>
              <ScrollbarAlwaysOn width='100%' height='100%' showArrows>
                <Box display='flex'>
                  {_partner2022.map((_category) => {
                    if (_category) {
                      return (
                        _category.partner &&
                        _category.partner.length > 0 && (
                          <>
                            <Box key={_category.code} width={150} mx={1} mb={2}>
                              <img
                                style={{
                                  border: '2px solid #a4c627',
                                }}
                                width='150px'
                                src={
                                  _category.file &&
                                  _category.file.code &&
                                  `${process.env.GATSBY_CMS_API_URL}/static/${_category.file.code}`
                                }
                                alt='category'
                              />
                            </Box>
                            {_category &&
                              _category.partner &&
                              _category.partner.length > 0 &&
                              _category.partner.map((_partner) => {
                                return (
                                  <Box key={_partner.code} width={150} mx={1} mb={2}>
                                    <img
                                      width='150px'
                                      src={
                                        _partner.file &&
                                        _partner.file.code &&
                                        `${process.env.GATSBY_CMS_API_URL}/static/${_partner.file.code}`
                                      }
                                      alt='category'
                                    />
                                  </Box>
                                );
                              })}
                          </>
                        )
                      );
                    } else {
                      _partnersCount++;
                      if (_partnersCount === _partner2022.length) {
                        setIsShowPartners(false);
                      }
                    }
                  })}
                </Box>
              </ScrollbarAlwaysOn>
            </SectionContainerLayoutWithFilter>
          )}
        </Grid>
        <Grid item xs={12}>
          <SectionContainerLayoutWithFilter
            title='GMIS AMERICA 2022 Speakers'
            viewAllText='View All'
            baseLink='/speakers/2022'>
            <>
              <CustomGrid>
                {speakersSectionData.map((speaker, i) => (
                  <CustomGridItem key={`${speaker.code}${i}`}>
                    <AnimatedCard
                      bio={speaker.bio && speaker.bio !== 'NULL' && speaker.bio !== 'null' ? speaker.bio : null}
                      image={speaker.file.code}
                      title={
                        speaker.salutationId === 7 || speaker.salutation.name === 'NULL'
                          ? speaker.firstName + ' ' + speaker.lastName
                          : speaker.salutation.name + ' ' + speaker.firstName + ' ' + speaker.lastName
                      }
                      body={
                        speaker.organization && speaker.organization.length > 1
                          ? speaker.designation + ', ' + speaker.organization
                          : speaker.designation
                      }
                    />
                  </CustomGridItem>
                ))}
              </CustomGrid>
              {!isSpeakersShownAll && speakers2022.length > speakersSectionData.length && (
                <Box mt={3}>
                  <Button fullWidth variant='contained' color='primary' onClick={toggleViewAllSpeakers}>
                    VIEW MORE SPEAKERS
                  </Button>
                </Box>
              )}
            </>
          </SectionContainerLayoutWithFilter>
        </Grid>
        <Grid item xs={12}>
          {newsData.length > 0 ? (
            <Grid item xs={12}>
              <SectionContainerLayoutWithFilter
                title='GMIS AMERICA 2022 NEWS'
                isViewAll
                viewAllText='View all'
                baseLink='/news'>
                <SectionContentFeaturedList
                  primaryMediaType='image'
                  secondaryMediaType='image'
                  apiData
                  isNews
                  linkText='Read more'
                  data={newsData}
                />
              </SectionContainerLayoutWithFilter>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </Layout>
  );
};

const useStyles = makeStyles((theme) => ({
  contentParagraph: {
    marginBottom: theme.spacing(1),
  },

  boxStyle: {
    left: '50%',
    transform: 'translateX(-50%)',
  },

  contentGreen: {
    color: colors.primary.dark,
    fontWeight: 'bolder',
  },
  agendaDate: {
    color: colors.primary.dark,
    marginBottom: theme.spacing(2),
    fontSize: '20px',
  },
  contentList: {
    position: 'relative',
    '& > li::marker': {
      color: colors.primary.dark,
    },
  },
}));

const getPageYearData = (items) => {
  return items.filter((item) => Number(item.year) === YEAR);
};

const axiosInstance = axios.create({
  baseURL: process.env.GATSBY_CMS_API_URL,
  proxy: {
    host: process.env.GATSBY_CMS_API_URL,
    port: 5000,
  },
});

const YEAR = 2022;

export default Page_2022;
